<template>
  <div class="container">
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingComponent",
});
</script>

<style lang="scss">
.container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 10px;
    height: 10px;
    margin: 1rem;
    background: $purple;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
  }
}
</style>
