import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// Pages
import Home from "../views/Home.vue";
import StudyItineraries2 from "../views/StudyItineraries2.vue";
import StudyItinerariesInternal from "../views/StudyItinerariesInternal.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  // {
  //   path: "/roteiros-de-estudos",
  //   name: "studyItineraries",
  //   component: StudyItineraries,
  // },
  {
    path: "/roteiros-de-estudos",
    name: "StudyItineraries2",
    component: StudyItineraries2,
  },
  {
    path: "/roteiros-de-estudos-interno",
    name: "studyItinerariesInternal",
    component: StudyItinerariesInternal,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
