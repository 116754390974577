import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import VueGtagPlugin from "vue-gtag";

createApp(App)
  .use(store)
  .use(router)
  .use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions)
  .use(
    VueGtagPlugin,
    {
      // includes: [{ id: "G-FWM9EK4T5Q" }],
      config: {
        id: "G-6F6JXWPFZB",
      },
    },
    router
  )
  .mount("#app");
