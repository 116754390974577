<template>
  <label
    class="tag-status"
    :class="{ pending: this.status === 'pending', inProgress: this.status === 'inProgress'}"
    :style="{ backgroundColor: getTagColor(this.status) }"
    >{{ this.text }}</label
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TagStatus",

  props: {
    text: String,
    status: String,
  },
  mounted() {
    this.text;
    this.status;
  },

  methods: {
    getTagColor(status: string) {
      switch (status) {
        case "done":
          return "#3BA550";
        case "late":
          return "#b50303";
        case "pending":
          return "#F0E9FB";
        default:
          return "#f9c339";
      }
    },
  },
});
</script>

<style lang="scss">
.tag-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  padding: 4px 8px;
  border-radius: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  height: 18px;
  color: #fff;
}

.pending {
  color: #0d0d0d;
  border: 2px solid #F9C339;
}

.inProgress {
  color: #0d0d0d;
}

@media (max-width: 425px) {
  .tag-status {
    font-size: 10px;

    padding: 2px 8px;
  }
}
</style>
