import { IStudent, ITotvsKey } from "@/interfaces/utils";
import { TotvsAPI } from "@/services/api";
import { createStore } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

interface RootState {
  userTotvs?:
    | ITotvsKey
    | {
        status_code: number;
        detail: string;
        headers: null | string;
      };
  key?: string;
  student?: IStudent[];
}

export default createStore<RootState>({
  state: {
    userTotvs: undefined,
    key: undefined,
    student: undefined,
  },
  getters: {},
  mutations: {
    loadTotvsKey(state, data) {
      state.userTotvs = data;
      localStorage.setItem("user", JSON.stringify(data));
    },

    saveKey(state, key) {
      state.key = key;
      localStorage.setItem("key", JSON.stringify(state.key));
    },

    loadKey(state, key) {
      state.key = key;
    },

    loadStudent(state, student) {
      state.student = student;
    },

    saveStudent(state, student) {
      state.student = student;
      localStorage.setItem("student", JSON.stringify(state.student));
    },
  },
  actions: {
    async loadKey({ commit }) {
      if (localStorage.getItem("key")) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        commit("loadKey", JSON.parse(localStorage.getItem("key")!));
      }
    },

    async saveKey({ commit }, key) {
      try {
        commit("saveKey", key);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchTotvsKey({ commit }, key) {
      try {
        const response = await TotvsAPI.get(
          `eex/tts_re?key=${key ? key : this.state.key}`,
          {
            headers: {
              "api-ssoft-key": `${process.env.SSOFT_KEY}`,
            },
          }
        );
        if (response?.data.detail === "Erro ao Validar Chave Ssoft") {
          toast.warning("Sua sessão expirou. Realize o login novamente.");
          setTimeout(
            () => (window.location.href = `${process.env.LOGIN_URL}`),
            3000
          );
        }
        commit("loadTotvsKey", response.data);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    },

    async loadStudent({ commit }) {
      if (localStorage.getItem("student")) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        commit("loadStudent", JSON.parse(localStorage.getItem("student")!));
      }
    },

    async fetchStudent({ commit }) {
      if (localStorage.getItem("user")) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const user = JSON.parse(localStorage.getItem("user")!);
          const response = await TotvsAPI.get(
            `ee/${user?.codperlet}/${user.codcoligada}/${
              user.codfilial
            }/cfo/${user.codusuariorespfinanceiro.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            )}/${user.ra}`,
            {
              headers: {
                "api-ssoft-key": `${process.env.SSOFT_KEY}`,
              },
            }
          );
          commit("saveStudent", response.data);
        } catch (error) {
          console.error("Erro na requisição:", error);
        }
      }
    },
  },
  modules: {},
});
