import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt", "height", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    style: _normalizeStyle({ width: this.iconSize })
  }, [
    _createElementVNode("img", {
      src: require(`@/assets/icons/${this.img}`),
      alt: this.alt,
      height: this.iconSize,
      width: this.iconSize
    }, null, 8, _hoisted_1)
  ], 4))
}