<template>
  <div class="student-header">
    <img src="@/assets/img/profile-picture-default.svg" alt="profile-picture" />
    <div>
      <h2>{{ this.student.nome_estudante }}</h2>
      <span>{{ this.student.nometurma }}</span>
      <a
        href="https://drive.google.com/file/d/1YV490izJ7z5R_L6MYXh-67HV0wzIIxza/view?_hsmi=299877808&_hsenc=p2ANqtz-_rPCc-OUvrmL6lTFnaBRWTZpFzpAMJDm9icDTDijgBhmDPeZb7byv4ayyNcEWtgMrEc0h4J-fXElg4HvsvZGXR4AcSz32u0Ykm0QyUWXGKdHaDprQ"
        target="_blank"
        ><img
          src="@/assets/icons/icon-open.svg"
          alt="profile-picture"
        />Calendário Escolar</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "StudentHeader",

  props: {
    student: { type: Object, required: true },
  },
});
</script>

<style lang="scss">
.student-header {
  display: flex;
  gap: 16px;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 4px 0;

    h2 {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      color: $orange;

      margin: 0;
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: $grey;
    }

    a {
      display: flex;
      align-items: center;
      gap: 0 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: uppercase;

      color: $purple;
      cursor: pointer;

      img {
        width: 15.75px;
        height: 15.75px;
      }
    }
  }
}

@media (max-width: 320px) {
  .student-header {
    width: 300px;
    padding: 6px;
  }
}

@media (max-width: 360px) {
  .student-header {
    div {
      h2 {
        font-size: 20px;
      }

      span {
        font-size: 14px;
      }

      a {
        font-size: 12px;
        text-transform: none;
      }
    }
  }
}

@media (max-width: 375px) {
  .student-header {
    div {
      h2 {
        font-size: 20px;
      }

      span {
        font-size: 14px;
      }

      a {
        font-size: 12px;
        text-transform: none;
      }
    }
  }
}
</style>
