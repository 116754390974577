<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <img src="@/assets/icons/icon-info.svg" alt="info" />
      <h2>Saiba mais: Roteiros de Estudos</h2>
      <span>
        Roteiro de Estudos tem como objetivo favorecer o estudo autônomo e
        avaliar conhecimentos toda semana, também ajuda a preparar e fixar os
        temas trabalhados nos Componentes.
      </span>
      <div>
        <button type="button" @click="openCloseModal">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultModal",
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openCloseModal(): void {
      this.showModal = !this.showModal;
    },
  },
});
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-content {
    display: flex;
    width: 539px;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    margin: auto;
    margin-top: 20%;

    border-radius: 16px;
    background: var(--grey-scale-white, #fff);
    box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.2);

    img {
      width: 37px;
      height: 37px;
    }

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      margin: 0;
    }

    span {
      margin-top: 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    div {
      display: flex;
      align-items: center;

      width: 100%;
      height: 37px;

      button {
        padding: 8px 16px;

        text-align: center;
        text-transform: uppercase;

        margin-left: auto;
        color: $purple;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
      :hover {
        padding: 6px 14px;
        border: 2px solid $purple;
      }
    }
  }
}
</style>
