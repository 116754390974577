<template>
  <div class="home">
    <AcessButton
      :label="'Roteiros responsavel'"
      :link="'/roteiros-de-estudos'"
    />
    <!-- <button>
      <router-link to="/roteiros-de-estudos-interno"
        >Roteiros usuario interno</router-link
      >
    </button> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AcessButton from "@/components/AccessButton.vue";
import store from "@/store";

export default defineComponent({
  name: "HomeView",
  components: {
    AcessButton
  },

  mounted() {
    store.dispatch("loadKey");
    store.dispatch("fetchTotvsKey", this.$route.query.key);
  },
});
</script>

<style lang="scss">
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
