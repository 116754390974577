<template>
  <div class="itineraries">
    <div class="header">
      <!-- <router-link to="/">
        <img src="@/assets/icons/icon-arrow-left.svg" alt="arrow" />
      </router-link> -->
      <h2 class="title">Roteiros de Estudos</h2>
      <img
        @click="openModal"
        src="@/assets/icons/icon-info-purple.svg"
        alt="info"
      />
    </div>

    <StudentHeader
      v-if="this.itineraries"
      :student="this.itineraries"
      :key="this.attKey"
    />

    <div v-else-if="this.error" class="loading">
      <span class="error"
        >Erro ao coletar dados do estudante. Tente novamente mais tarde!</span
      >
    </div>

    <div v-else class="loading">
      <h4 class="studentLoading">Processando dados do estudante...</h4>
      <Loading />
    </div>

    <h3>Acompanhamento dos roteiros de estudos</h3>

    <div
      v-if="this.itineraries && this.takeFirstWord(this.itineraries.série_ano)"
      class="loading"
    >
      <span class="error"
        >A pontuação no roteiro de estudos não compõe a nota do estudante no
        componente.</span
      >
    </div>

    <div v-if="this.itineraries">
      <div
        v-for="(itinerary, index) in this.itineraries.cursos"
        :key="index"
        class="itineraries-container"
      >
        <div class="container-title">
          <h3>{{ itinerary.nome }}</h3>
          <button
            @click="openAccordion(itinerary.curso_id)"
            :class="{ active: checkIfIsOpen(itinerary.curso_id) }"
          >
            {{ checkIfIsOpen(itinerary.curso_id) ? "Ver Menos" : "Ver Mais" }}
          </button>
        </div>
        <div
          v-if="
            accordionsOpen.find((acordion) => acordion === itinerary.curso_id)
          "
        >
          <div
            v-for="(theme, index) in itinerary.roteiros"
            :key="index"
            class="theme"
          >
            <div class="module">
              <strong>{{ theme.nome }}</strong>

              <!-- <span>{{ theme.data_inicio?.split("-").join("/") }}</span> -->

              <TagStatus
                :text="
                  this.hasDone(theme.modulos) === 'Atrasado'
                    ? this.hasDone(theme.modulos)
                    : this.hasDone(theme.modulos) === 'Em Andamento'
                    ? this.hasDone(theme.modulos)
                    : this.transformDate(theme.data_inicio)
                "
                :status="this.hasDone(theme.modulos, true)"
              />
            </div>

            <div class="note">
              <span
                ><strong>{{ theme.nota ? theme.nota : "-" }}</strong
                >/10pts</span
              >
            </div>

            <ul v-for="(modulo, index) in theme.modulos" :key="index">
              <div>
                <li>{{ modulo.nome }}</li>

                <img
                  width="15"
                  src="@/assets/icons/icon-check.svg"
                  alt="check"
                  v-if="modulo.status_entrega === 'Feito'"
                />
                <img
                  width="15"
                  src="@/assets/icons/icon-trace.svg"
                  alt="trace"
                  v-else
                />
              </div>
              <hr />
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="this.error" class="loading">
      <span class="error"
        >Erro ao coletar dados. Tente novamente mais tarde!</span
      >
    </div>

    <div v-else class="loading">
      <h4>Coletando dados...</h4>
      <Loading />
    </div>

    <div
      v-if="this.itineraries && !checkIfHasInineraries(this.itineraries)"
      class="loading"
    >
      <span class="error"
        >Os estudantes do 1º ao 3º não usam o Roteiro de Estudos.</span
      >
    </div>

    <Modal ref="modalRef" />
  </div>
</template>

<script lang="ts">
import { IStudent, ITotvsKey } from "@/interfaces/utils";
import { RoteirosAPI } from "@/services/api";
import store from "@/store";
import { defineComponent, ref } from "vue";
import { mapState } from "vuex";
import StudentHeader from "../components/StudentHeader.vue";
import TagStatus from "../components/TagStatus.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Modal from "../components/Modal.vue";
import Loading from "../components/Loading.vue";
import { event } from "vue-gtag";

interface ITtineraries {
  nome_estudante: string;
  ra: string;
  unidade: string;
  ciclo: string;
  série_ano: string;
  nometurma: string;
  cursos: {
    curso_id: number;
    nome: string;
    data_inicio: string;
    roteiros: {
      roteiro_id: number;
      nome: string;
      visivel: number;
      data_inicio: string;
      nota: number;
      modulos: {
        modulo_id: number;
        nome: string;
        status_entrega: string;
        entrega: string;
      }[];
    }[];
  }[];
}

interface IData {
  student?: IStudent[];
  attKey: number;
  itineraries?: ITtineraries;
  accordionsOpen: string[];
  error: boolean;
}

export default defineComponent({
  name: "StudyItineraries2",
  components: {
    StudentHeader,
    TagStatus,
    Modal,
    Loading,
  },

  setup() {
    const modalRef = ref<typeof Modal>();

    const openModal = () => {
      modalRef.value?.openCloseModal();
    };

    return { modalRef, openModal };
  },

  data(): IData {
    return {
      student: undefined,
      attKey: 0,
      itineraries: undefined,
      accordionsOpen: [],
      error: false,
    };
  },

  computed: mapState(["userTotvs", "key"]),

  mounted() {
    store.dispatch("loadKey");
    store.dispatch("fetchTotvsKey", this.$route.query.key);
    if (this.$route.query.key) {
      store.dispatch("saveKey", this.$route.query.key);
    }
  },

  methods: {
    handleStudent() {
      if (this.$route.query) {
        localStorage.setItem(
          "ano",
          JSON.stringify(this.$route.query.anoletivo)
        );
      }

      const fetchItineraries = async () => {
        try {
          const itinerariesResponse = await RoteirosAPI.get(
            `api/roteiros_estudante?anoletivo=${
              this.$route.query.anoletivo ? this.$route.query.anoletivo : "2024"
            }&key=${this.$route.query.key ? this.$route.query.key : this.key}`
          );
          if (
            itinerariesResponse.status === 200 &&
            itinerariesResponse.data.status_code === 500
          ) {
            toast.error(
              "Estamos sofrendo uma instabilidade no sistema. Por favor tente mais tarde."
            );
            this.error = true;
          } else if (
            itinerariesResponse.status === 200 &&
            itinerariesResponse.data
          ) {
            this.itineraries = itinerariesResponse.data;
            this.attKey += 1;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          if (err.response) {
            if (err.response.status === 502) {
              toast.error(
                "Estamos sofrendo uma instabilidade no sistema. Por favor tente mais tarde."
              );
            } else {
              toast.error("Erro na requisição");
            }
            this.error = true;
          }
        }
      };

      fetchItineraries();
    },

    hasDone(
      values: {
        status_entrega: string;
        modulo_id: number;
        nome: string;
        entrega: string;
      }[],
      status?: boolean
    ) {
      if (status) {
        const hasDone = values.find(
          (module) => module.status_entrega === "Pendente"
        )
          ? "pending"
          : values.find((module) => module.status_entrega === "Atrasado")
          ? "late"
          : values.find((module) => module.status_entrega === "Em Andamento")
          ? "inProgress"
          : "done";

        return hasDone;
      } else {
        const hasDone = values.find(
          (module) => module.status_entrega === "Pendente"
        )
          ? "Pendente"
          : values.find((module) => module.status_entrega === "Atrasado")
          ? "Atrasado"
          : values.find((module) => module.status_entrega === "Em Andamento")
          ? "Em Andamento"
          : "Feito";

        return hasDone;
      }
    },

    transformDate(date: string) {
      if (date) {
        const transformedDate = date.split("-").join("/");

        return transformedDate;
      } else {
        return "--";
      }
    },

    openAccordion(id: string) {
      const index = this.accordionsOpen.indexOf(id);

      if (index !== -1) {
        this.accordionsOpen.splice(index, 1);
      } else {
        this.accordionsOpen.push(id);
      }
    },

    checkIfIsOpen(id: string) {
      const isOpen = this.accordionsOpen.find((acordion) => acordion === id);
      return isOpen ? true : false;
    },

    takeFirstWord(value: string) {
      if (value.split(" ")[0] === "5º" || value.split(" ")[0] === "4º") {
        return true;
      } else {
        return false;
      }
    },
    checkIfHasInineraries(value: ITtineraries) {
      if (value?.cursos) {
        return true;
      } else {
        return false;
      }
    },
    track(values: ITotvsKey) {
      event("RA", { RA: values.ra });
      event("Matricula", { RA_ALUNO: values.ra });
    },
  },

  watch: {
    userTotvs: {
      handler() {
        if (this.userTotvs) {
          this.handleStudent();
          this.track(this.userTotvs);
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
.itineraries {
  width: 960px;
  padding: 8px;

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: Poppins;

    h4 {
      font-size: 24px;
      font-weight: 600;
      text-align: center;

      &.studentLoading {
        font-size: 18px;
      }
    }
  }

  .error {
    margin-top: 1em;
  }

  .header {
    display: flex;
    gap: 4px;
    align-items: center;

    img {
      height: 32px;
      width: 32px;
    }

    img:last-of-type {
      margin-left: auto;
      cursor: pointer;
    }

    h2.title {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      color: $purple;
    }
  }

  h3 {
    margin-top: 1.5em;
    margin-bottom: 0;
  }

  .itineraries-container {
    display: flex;
    flex-direction: column;

    background-color: $light_purple;

    border-radius: 20px;
    margin-top: 1em;
    padding: 24px 16px;

    .container-title {
      h3 {
        margin-top: 0;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: #fff;
        border: 2px solid $purple;

        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        &.active {
          background-color: $purple;
          color: #fff;
        }
      }
    }

    .theme {
      transition: height 1.5s ease;

      margin-top: 0.5em;

      .module {
        margin-top: 1em;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .note {
        width: 100%;
        display: flex;

        span {
          margin-left: auto;
          font-size: 14px;
          strong {
            font-size: 16px;
          }
        }
      }

      ul {
        margin: 0;
        margin-top: 1em;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          li {
            margin-top: 4px;
          }
        }

        hr {
          border: 1px solid #cccccc;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .itineraries {
    width: 700px;
  }
}

@media (max-width: 768px) {
  .itineraries {
    width: 700px;
  }
}

@media (max-width: 425px) {
  .itineraries {
    width: 400px;

    .itineraries-container {
      .container-title {
        h3 {
          font-size: 16px;
          width: 200px;
        }
        gap: 0 8px;
      }

      .theme {
        .module {
          strong {
            margin-top: 1em;

            font-size: 14px;
            width: 200px;
          }
        }

        ul {
          div {
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .itineraries {
    width: 360px;
  }
}

@media (max-width: 360px) {
  .itineraries {
    width: 340px;
  }
}

@media (max-width: 320px) {
  .itineraries {
    width: 300px;

    .itineraries-container {
      .container-title {
        h3 {
          font-size: 14px;
          width: 150px;
        }
      }

      .theme {
        .module {
          strong {
            width: 150px;
          }
        }
      }
    }
  }
}
</style>
