<template>
  <div class="study-itineraries-internal">
    <h2>Roteiros de Estudo</h2>
    <div class="filters">
      <ButtonGroup
        class="button"
        :label="'Ciclo'"
        :options="this.mokiCiclo"
        :value="this.cicloValue"
        @update-value="updateCiclo"
      />
      <ButtonGroup
        class="button"
        :label="'Ano'"
        :options="this.mokiAno"
        :value="this.anoValue"
        @update-value="updateAno"
      />
      <ButtonGroup
        class="button"
        :label="'Turma'"
        :options="this.mokiTurma"
        :value="this.turmaValue"
        @update-value="updateTurma"
      />
    </div>
    <h3>Ensino Fundamental 1 - 1ºA</h3>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>
              <div>
                Estudantes
                <img
                  src="@/assets/icons/icon-arrow-drop-down.svg"
                  alt="arrow"
                />
              </div>
            </th>
            <th>RA</th>
          </tr>
        </thead>
        <tbody v-for="(student, index) in mokiStudents" :key="index">
          <tr @click="this.selectStudent(student)">
            <td>{{ student.name }}</td>
            <td>{{ student.ra }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Drawer ref="drawerRef" :studentName="this.studentSelected.name" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// Components
import ButtonGroup from "./../components/ButtonGroup.vue";
import Drawer from "./../components/Drawer.vue";

export default defineComponent({
  name: "StudyIntineriesInternal",
  data() {
    return {
      studentSelected: {
        name: "",
        ra: "",
      },
      cicloValue: 2,
      anoValue: 1,
      turmaValue: 5,
      mokiCiclo: [
        {
          value: 1,
          label: "EF I",
        },
        {
          value: 2,
          label: "EF II",
        },
        {
          value: 3,
          label: "EM",
        },
      ],
      mokiAno: [
        {
          value: 1,
          label: "6º",
        },
        {
          value: 2,
          label: "7º",
        },
        {
          value: 3,
          label: "8º",
        },
        {
          value: 4,
          label: "9º",
        },
      ],
      mokiTurma: [
        {
          value: 1,
          label: "A",
        },
        {
          value: 2,
          label: "B",
        },
        {
          value: 3,
          label: "C",
        },
        {
          value: 4,
          label: "D",
        },
        {
          value: 5,
          label: "E",
        },
      ],
      mokiStudents: [
        {
          name: "Bob Esponja",
          ra: "A-01PEN-224815",
        },
        {
          name: "Patrick Estrela",
          ra: "A-01PEN-224815",
        },
        {
          name: "Ana Maria Braga e Louro",
          ra: "A-01PEN-224815",
        },
      ],
    };
  },

  components: {
    ButtonGroup,
    Drawer,
  },

  setup() {
    const drawerRef = ref<typeof Drawer>();

    const openDrawer = () => {
      drawerRef.value?.openCloseDrawer();
    };

    return { drawerRef, openDrawer };
  },

  methods: {
    updateCiclo(value: number) {
      this.cicloValue = value;
    },

    updateAno(value: number) {
      this.anoValue = value;
    },

    updateTurma(value: number) {
      this.turmaValue = value;
    },

    selectStudent(student: { name: string; ra: string }) {
      this.studentSelected = student;
      this.openDrawer();
    },
  },
});
</script>

<style lang="scss">
.study-itineraries-internal {
  width: 960px;
  padding: 8px;

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    color: $purple;
  }

  div.filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    .button {
      width: 100%;
    }
  }

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-top: 24px;
  }

  .table {
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        border-bottom: 2px solid $light_grey;
        tr {
          th {
            text-align: start;
            padding: 8px 16px;

            div {
              display: flex;
              flex-wrap: nowrap;

              img {
                cursor: pointer;
              }
            }
          }
        }
      }

      tbody {
        cursor: pointer;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border-bottom: 1px solid $light_grey;

        tr {
          td {
            text-align: start;
            padding: 8px 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .study-itineraries-internal {
    width: 960px;
  }
}

@media (max-width: 768px) {
  .study-itineraries-internal {
    width: 600px;
  }
}

@media (max-width: 425px) {
  .study-itineraries-internal {
    width: 328px;

    .table {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

@media (max-width: 320px) {
  .study-itineraries-internal {
    width: 280px;
  }
}
</style>
