import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "button-group" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass({ unselected: option.value !== this.value }),
            onClick: ($event: any) => (this.updateValue(option.value))
          }, _toDisplayString(option.label), 11, _hoisted_2)
        ]))
      }), 128))
    ])
  ]))
}