<template>
  <div class="button-group">
    <label>{{ label }}</label>
    <div>
      <div v-for="(option, index) in this.options" :key="index">
        <button
          type="button"
          :class="{ unselected: option.value !== this.value }"
          @click="this.updateValue(option.value)"
        >
          {{ option.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ButtonGroup",

  props: {
    label: { type: String, required: false },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      validator: (value: unknown) => {
        return typeof value === "number" || typeof value === "string";
      },
      required: true,
    },
  },
  mounted() {
    this.label;
    this.options;
    this.value;
  },

  methods: {
    updateValue(value: number | string): void {
      this.$emit("update-value", value);
    },
  },
});
</script>

<style lang="scss">
.button-group {
  max-width: 228px;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  div {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;

    border: 2px solid $light_grey;

    height: 37px;
    width: 100%;

    div {
      margin: 0;
      border: none;
      padding: 4px;

      button {
        height: 29px;
        width: 100%;

        color: #fff;
        background-color: $purple;

        border-radius: 4px;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        transition: 0.5s;
      }

      button.unselected {
        color: $light_grey;
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 425px) {
  .button-group {
    max-width: 100%;
  }
}
</style>
