<template>
  <div v-if="drawer" class="drawer">
    <div
      class="drawer-container"
      :class="{ 'slide-content': !this.drawer, hidden: this.drawer }"
    >
      <div class="header">
        <p>
          {{ studentName }}
        </p>
        <img
          @click="openCloseDrawer"
          src="@/assets/icons/icon-close.svg"
          alt="close-drawer"
        />
      </div>
      <div class="body">
        <span class="guardian-vision">Visão do responsável</span>
        <StudentHeader />

        <h3>Acompanhamento dos roteiros de estudos</h3>

        <div class="itineraries-container">
          <div class="itineraries-header">
            <button class="button-today">HOJE</button>
            <div><span>Agosto 2023</span> <span>Dias 21 a 25 (R.1)</span></div>
            <div>
              <ButtonIcon
                :img="'icon_navigate_before.svg'"
                :alt="'before-icon'"
                :iconSize="24"
              />
              <ButtonIcon
                :img="'icon_navigate_next.svg'"
                :alt="'before-icon'"
                :iconSize="24"
              />
            </div>
          </div>
          <div
            class="week-itineraries"
            v-for="(schoolSubjects, index) in this.mokiItens"
            :key="index"
            :class="{
              border:
                this.mokiItens[this.mokiItens.length - 1].id !==
                schoolSubjects.id,
              upcoming: this.mokiItens[0].id === schoolSubjects.id,
            }"
          >
            <div>
              <img
                :src="
                  require(`@/assets/icons/curricular-components/${schoolSubjects.icon}.svg`)
                "
                :alt="schoolSubjects.icon"
              />

              <div>
                <div class="info">
                  <p>{{ schoolSubjects.nome_roteiro }} <br /></p>
                  <TagStatus
                    :text="
                      schoolSubjects.status == 'done' ? 'Feito' : 'Em Andamento'
                    "
                    :status="schoolSubjects.status"
                  />
                </div>
                <div class="info">
                  <span class="first">{{ schoolSubjects.text }}</span>
                </div>
                <span
                  class="note"
                  :class="{
                    last:
                      this.mokiItens[this.mokiItens.length - 1].id ===
                      schoolSubjects.id,
                  }"
                >
                  <strong v-if="schoolSubjects.nota !== null">{{
                    schoolSubjects.nota
                  }}</strong>
                  <strong v-else>-</strong>
                  /10pts
                </span>
              </div>
            </div>
          </div>
        </div>

        <h3>Coloque em dia</h3>

        <div
          class="itineraries-container"
          v-for="(atrasados, index) in this.mokiAtrasados"
          :key="index"
        >
          <div class="itineraries-header">
            <div class="text">
              <span>{{ atrasados.mes }} {{ atrasados.ano }}</span>
              <span
                >Dias {{ atrasados.inicial_date }} a {{ atrasados.final_date }}
                {{ `(R.${atrasados.sequencia})` }}</span
              >
            </div>
          </div>
          <div
            class="week-itineraries"
            v-for="(item, index) in atrasados.roteiros"
            :key="index"
            :class="{
              border:
                atrasados.roteiros[atrasados.roteiros.length - 1].id !==
                item.id,
              upcoming: atrasados.roteiros[0].id === item.id,
            }"
          >
            <div>
              <img
                :src="
                  require(`@/assets/icons/curricular-components/${item.icon}.svg`)
                "
                :alt="item.icon"
              />

              <div>
                <div class="info">
                  <p>{{ item.nome_roteiro }} <br /></p>
                  <TagStatus :text="'Atrasado'" :status="item.status" />
                </div>
                <div class="info">
                  <span class="first">{{ item.text }}</span>
                </div>
                <span
                  class="note"
                  :class="{
                    last:
                      atrasados.roteiros[atrasados.roteiros.length - 1].id ===
                      item.id,
                  }"
                >
                  <strong v-if="item.nota !== null">{{ item.nota }}</strong>
                  <strong v-else>-</strong>
                  /10pts
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// Components
import StudentHeader from "./StudentHeader.vue";
import ButtonIcon from "./ButtonIcon.vue";
import TagStatus from "./TagStatus.vue";

export default defineComponent({
  name: "DefaultDrawer",
  components: { StudentHeader, ButtonIcon, TagStatus },
  data() {
    return {
      drawer: false,
      mokiItens: [
        {
          id: 1,
          nome_roteiro: "Ciencia da Natureza",
          icon: "biologia",
          sequencia: 5,
          semana: 1,
          status: "done",
          nota: 0,
          text: "R0.0: Lorem ipsum dolor sit amet, consecteturrrr adipiscing elit.",
        },
        // {
        //   id: 2,
        //   nome_roteiro: "Corpo e Movimento",
        //   icon: "corpo-movimento",
        //   sequencia: 5,
        //   semana: 1,
        //   status: "done",
        //   nota: 0,
        //   text: "R0.0: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        // },
        {
          id: 3,
          nome_roteiro: "Corpo e Movimento",
          icon: "corpo-movimento",
          sequencia: 5,
          semana: 1,
          status: "pending",
          nota: null,
          text: "R0.0: Lorem ipsum dolor sit amet, consecteturrrr adipiscing elit.",
        },
      ],
      mokiAtrasados: [
        {
          id: 5,
          final_date: 25,
          inicial_date: 21,
          ano: 2023,
          mes: "Agosto",
          sequencia: 5,
          roteiros: [
            {
              id: 2,
              nome_roteiro: "Corpo e Movimento",
              icon: "corpo-movimento",
              semana: 1,
              status: "late",
              nota: null,
              text: "R5.1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
            {
              id: 3,
              nome_roteiro: "Corpo e Movimento",
              icon: "corpo-movimento",
              semana: 1,
              status: "late",
              nota: null,
              text: "R5.1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
          ],
        },
        {
          id: 5,
          final_date: 25,
          inicial_date: 21,
          ano: 2023,
          mes: "Agosto",
          sequencia: 5,
          roteiros: [
            {
              id: 3,
              nome_roteiro: "Corpo e Movimento",
              icon: "corpo-movimento",
              semana: 1,
              status: "late",
              nota: null,
              text: "R5.1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
          ],
        },
      ],
    };
  },
  props: {
    studentName: { type: String, required: false },
  },
  mounted() {
    this.studentName;
  },
  methods: {
    openCloseDrawer() {
      this.drawer = !this.drawer;
    },
  },
});
</script>

<style lang="scss">
.drawer {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .slide-content {
    width: 100%;
    height: 100%;
    background-color: lightblue;
    transition: transform 1s ease-in-out;

    &.hidden {
      transform: translateX(-100%);
    }
  }

  div.drawer-container {
    width: 551px;

    position: absolute;
    top: 0;
    right: 0;

    .header {
      height: 48px;
      background-color: $yelow_secondary;

      display: flex;
      align-items: center;

      padding: 16px;

      p {
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      img {
        cursor: pointer;
      }
    }
    .body {
      background-color: #fff;

      display: flex;
      flex-direction: column;
      padding: 16px;

      span.guardian-vision {
        margin-bottom: 1em;

        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }

      h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin: 0;
        margin-top: 1em;
      }

      .itineraries-container {
        display: flex;
        flex-direction: column;

        background-color: $light_purple;

        border-radius: 20px;
        margin-top: 1em;
        padding: 24px 16px;

        .itineraries-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;

          .button-today {
            padding: 4px 8px;
            border: 2px solid $purple;

            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            color: $purple;
            text-transform: uppercase;
          }

          div {
            display: flex;
            flex-direction: column;

            text-align: center;

            span {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            span:first-of-type {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          div:last-of-type {
            flex-direction: row;
            gap: 0 4px;
          }

          div.text {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        .upcoming {
          margin-top: 0px;
        }

        .week-itineraries {
          display: flex;
          flex-direction: column;
          margin-top: 8px;

          div {
            display: flex;

            img {
              width: 40px;
              height: 40px;
              margin-right: 4px;
            }

            div {
              width: 100%;

              display: flex;
              flex-direction: column;

              span {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                strong {
                  font-size: 14px;
                }
              }

              .note {
                margin: 5.5px 0 9.5px auto;
              }

              .first {
                margin-top: 4px;
              }

              .last {
                margin: 5.5px 0 0 auto;
              }

              div.info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p {
                  margin: 0;

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .border {
          border-bottom: 1px solid $light_grey;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .drawer {
    max-width: 425px;

    .slide-content {
      width: 100%;
      height: 100%;
      background-color: lightblue;
      transition: transform 1s ease-in-out;

      &.hidden {
        transform: translateX(-100%);
      }
    }

    div.drawer-container {
      width: 100%;

      .itineraries-container {
        .week-itineraries {
          div {
            div {
              span {
                font-size: 12px;

                strong {
                  font-size: 14px;
                }
              }

              div.info {
                p {
                  font-size: 10px;
                }
              }
            }
          }
        }

        .itineraries-header {
          div {
            span {
              font-size: 14px;
            }

            span:first-of-type {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
