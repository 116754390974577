<template>
  <button :style="{ width: this.iconSize }">
    <img
      :src="require(`@/assets/icons/${this.img}`)"
      :alt="this.alt"
      :height="this.iconSize"
      :width="this.iconSize"
    />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ButtonIcon",

  props: {
    img: String,
    alt: String,
    iconSize: Number,
  },
  mounted() {
    this.img;
    this.alt;
    this.iconSize;
  },
});
</script>

<style lang="scss">
button {
  border: none;
  background-color: transparent;

  cursor: pointer;
}
</style>
